// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["r31wie9GJ", "YUm7eZy3b"];

const serializationHash = "framer-d497v"

const variantClassNames = {r31wie9GJ: "framer-v-1pdasjp", YUm7eZy3b: "framer-v-1o1bm3y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Mobile Menu [Open]": "YUm7eZy3b", "Mobile Menu": "r31wie9GJ"}

const getProps = ({height, hover, hover2, iconColor, id, tap, width, ...props}) => { return {...props, ccGxPxx4k: hover ?? props.ccGxPxx4k, E6NV4Gf2f: tap ?? props.E6NV4Gf2f, lYvpkC7IW: iconColor ?? props.lYvpkC7IW ?? "var(--token-963ba7ff-1506-4ab8-a9bc-332b05614e66, rgb(9, 9, 11))", rDm5rh2N3: hover2 ?? props.rDm5rh2N3, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "r31wie9GJ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;iconColor?: string;hover?: any;hover2?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, E6NV4Gf2f, lYvpkC7IW, ccGxPxx4k, rDm5rh2N3, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "r31wie9GJ", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapld1u9f = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (E6NV4Gf2f) {const res = await E6NV4Gf2f(...args);
if (res === false) return false;}
setVariant("YUm7eZy3b")
})

const onMouseEnter156h014 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (ccGxPxx4k) {const res = await ccGxPxx4k(...args);
if (res === false) return false;}
if (rDm5rh2N3) {const res = await rDm5rh2N3(...args);
if (res === false) return false;}
})

const onTaplw2b9f = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (E6NV4Gf2f) {const res = await E6NV4Gf2f(...args);
if (res === false) return false;}
setVariant("r31wie9GJ")
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1pdasjp", className, classNames)} data-framer-name={"Mobile Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"r31wie9GJ"} onMouseEnter={onMouseEnter156h014} onTap={onTapld1u9f} ref={refBinding} style={{...style}} {...addPropertyOverrides({YUm7eZy3b: {"data-framer-name": "Mobile Menu [Open]", onTap: onTaplw2b9f}}, baseVariant, gestureVariant)}><motion.div className={"framer-1kp3lvs"} layoutDependency={layoutDependency} layoutId={"xRC69Zxb5"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{YUm7eZy3b: {rotate: 45}}}/><motion.div className={"framer-xhw3l6"} layoutDependency={layoutDependency} layoutId={"bs7RBtcd0"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{YUm7eZy3b: {rotate: -45}}}/><motion.div className={"framer-sbx5ne"} layoutDependency={layoutDependency} layoutId={"lW1Qc6BV1"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, opacity: 1, rotate: 0}} variants={{YUm7eZy3b: {opacity: 0, rotate: -45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-d497v.framer-rb2iua, .framer-d497v .framer-rb2iua { display: block; }", ".framer-d497v.framer-1pdasjp { cursor: pointer; height: 48px; position: relative; width: 48px; }", ".framer-d497v .framer-1kp3lvs { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 14px; width: 26px; }", ".framer-d497v .framer-xhw3l6 { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 22px; width: 26px; }", ".framer-d497v .framer-sbx5ne { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 30px; width: 19px; }", ".framer-d497v.framer-v-1o1bm3y.framer-1pdasjp { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 48px); }", ".framer-d497v.framer-v-1o1bm3y .framer-1kp3lvs { left: calc(50.00000000000002% - 26px / 2); top: 23px; }", ".framer-d497v.framer-v-1o1bm3y .framer-xhw3l6 { top: 23px; }", ".framer-d497v.framer-v-1o1bm3y .framer-sbx5ne { bottom: 18px; height: unset; left: 13px; width: 13px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"YUm7eZy3b":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"E6NV4Gf2f":"tap","lYvpkC7IW":"iconColor","ccGxPxx4k":"hover","rDm5rh2N3":"hover2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOZDCSSTjy: React.ComponentType<Props> = withCSS(Component, css, "framer-d497v") as typeof Component;
export default FramerOZDCSSTjy;

FramerOZDCSSTjy.displayName = "Menu/Hamburger Menu 2";

FramerOZDCSSTjy.defaultProps = {height: 48, width: 48};

addPropertyControls(FramerOZDCSSTjy, {variant: {options: ["r31wie9GJ", "YUm7eZy3b"], optionTitles: ["Mobile Menu", "Mobile Menu [Open]"], title: "Variant", type: ControlType.Enum}, E6NV4Gf2f: {title: "Tap", type: ControlType.EventHandler}, lYvpkC7IW: {defaultValue: "var(--token-963ba7ff-1506-4ab8-a9bc-332b05614e66, rgb(9, 9, 11)) /* {\"name\":\"Gray 950\"} */", title: "Icon Color", type: ControlType.Color}, ccGxPxx4k: {title: "Hover", type: ControlType.EventHandler}, rDm5rh2N3: {title: "Hover 2", type: ControlType.EventHandler}} as any)

addFonts(FramerOZDCSSTjy, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})